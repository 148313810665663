(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('AgenceSetupController', AgenceSetupController);

    AgenceSetupController.$inject = ['$scope', "leafletMapEvents",'$stateParams','$state', 'entity', 'Agence'];

    function AgenceSetupController ($scope, leafletMapEvents, $stateParams, $state, entity, Agence) {
        var vm = this;
        vm.agence = entity;
        vm.onlinePaymentTypes = [{code:'MTN_USSD_V1', label:'MTN USSD'}];

        vm.timezones = Agence.getTimezones();
        vm.updateCoord = function(){
          	
        	//On remet à 0 en cas d'erreur sur le champs.
        	if(! vm.agence.lat ){
        		vm.agence.lat = 0;
        	}
        	if(! vm.agence.lon){
        		vm.agence.lon = 0;
        	}

        	if( vm.agence.geographicCoord){
        		vm.geometry = vm.agence.geographicCoord;
        	}
        	
	        angular.extend($scope, {
	            defaults: {
	                scrollWheelZoom: false
	            },
//	            events: {
//	                map: {
//	                    enable: [ 'drag', 'click'],
//	                    logic: 'emit'
//	                }
//	            },
	            markers: {
	                Area: {
	                    lat: vm.agence.lat,
	                    lng: vm.agence.lon,
//	                    message: "This is Madrid. But you can drag me to another position",
	                    focus: true,
	                    draggable: true
	                }
	            }
	        })
        }
        
        vm.load = function(id) {
            Agence.get({id : id}, function(result) {
                vm.agence = result;

            	vm.updateCoord ();
               
                
            });
        };
        
        if(entity && entity.$promise){
            entity.$promise.then(function(value) {
            	vm.updateCoord ();
                Agence.getClientPortalConf({idArea : vm.agence.id}, function(result) {
                	vm.agence.clientPortalConf =  result;
                });

                Agence.getOnlinePaymentConf({idArea : vm.agence.id}, function(result) {
                	vm.agence.areaConfOnlinePayment =  result;
                },  function(error) {
                	//on ne fait rien en cas de 404
                });
            });	
        }else{
        	vm.updateCoord();
        }

        $scope.$on("leafletDirectiveMarker.dragend", function(event, args){
        	 vm.agence.lat = args.model.lat;
        	 vm.agence.lon = args.model.lng;
        });


        vm.save = function () {
        	Agence.update(vm.agence, onSaveSuccess, onSaveError);
        };

        vm.saveClientPortalConf = function () {
        	Agence.saveClientPortalConf(vm.agence.clientPortalConf, onSaveSuccessClientPortalConf, onSaveError);
        };

        vm.saveOnlinePaymentConf = function () {
        	vm.agence.areaConfOnlinePayment.agence  = {id:vm.agence.id};
        	Agence.saveOnlinePaymentConf (vm.agence.areaConfOnlinePayment, onSaveSuccessOnlinePaymentConf, onSaveError);
        };
        var onSaveSuccessOnlinePaymentConf = function (result) {
            $scope.$emit('wamasysApp:onlinePaymentConfUpdate', result);
        	vm.agence.areaConfOnlinePayment =  result;
        };
        
        
        vm.saveGeometryConf = function () {
        	var data = {agence: vm.agence ,geometry: vm.geometry  };
        	Agence.saveGeometryConf (data, onSaveSuccessGeometryConf , onSaveError);
        };
        var onSaveSuccessGeometryConf = function (result) {
            $scope.$emit('wamasysApp:geometryConfUpdate', result);
        };

        
        var onSaveSuccessClientPortalConf = function (result) {
            $scope.$emit('wamasysApp:clientPortalConfUpdate', result);
        	vm.agence.clientPortalConf =  result;
        };
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:agenceUpdate', result);
         //   $state.go('area-setup', {id:vm.agence.id}, { reload: true });
        };

        var onSaveError = function () {
            vm.isSaving = false;
            vm.isSavingAreaConfForm = false;
            vm.isSavingOnlinePaymentConfForm = false;
            vm.isSavingEditGeometryForm = false;
        };
        
        vm.clear = function() {
            $state.go('area-setup', {id:vm.agence.id}, { reload: true });
        };
        

    }
})();
